
.input_wrapper {
  background: #f2f4f6;
  padding: 10px 0px;
  width: 60%;
  color: #001d56;
  font-size: 16px !important;
}

.management_textInput {
  height: 40px;
  width: 527px;
  padding: 7px 8px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid gray;
  background-color: white;
}

.sort {
  font-size: 18px;
  color: #001d56;
  font-weight: 700;
}


.MuiChip-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  color: #1b1ba6;
  font-weight: 500;
}


.MuiSvgIcon-root {
  margin-right: -8;
}



.deal_input_wrapper {
  background: #f7f8fa;
  padding: 10px 30px;
  color: rgb(4, 4, 84);
  font-weight: 900;
  border-radius: 10px;
  margin-top: 31px;
}

.structure_paragraph {
  background: #f7f8fa;
  padding: 13px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
}


.document_download {
  background: #f7f8fa;
  padding: 8px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300;
  height: 18px;
  margin-top: 20;
}

.team_profile_card_wrapper {
  display: flex;
  padding: 20;
  border-radius: 12px;
  box-shadow: 0 0 9px #f3f3f3;
  border: 4px solid #f3f3f3;
}

.team_profile_card_paragraph {
  background: #f7f8fa;
  border-radius: 12px;
  margin: 8px 0;
}

.team_profile_card_p {
  font-weight: 400;
  margin: 0;
  font-size: 15px;
  line-height: 16px;
  color: #323232;
  padding: 5px;
}

.chip_executive {
  margin: 8px 0px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  color: rgb(50, 50, 50);
}

.details_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.6px;
  color: #323232;
  margin: "24px 0px";
}

.debt_profile_paragraph {
  font-size: 20px;
  line-height: 24px;
  color: #323232;
}


.grid_box {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #001d56;
}

.disable_button {
  background: rgb(133, 127, 127);
  border-radius: 50px;
  width: 134px;
  height: 42px;
  color: #afa9a9;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.45px;
}

.deal_button {
  background: linear-gradient(
    108.82deg,
    rgb(0, 62, 143) 25.52%,
    rgb(0, 29, 86) 85.05%
  );
  border-radius: 50px;
  width: 134px;
  height: 42px;
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.45px;
  cursor: pointer;
}

.buttonTextStyle {
  text-transform: none !important;
}

.rating_chip {
  display: flex;
  align-items: center;
  height: 27px;
  background: #d3d8e5;
  color: #001d56;
  width: 93px;
  border-radius: 28px;
  padding: 0 3px;
  font-size: 14px;
  line-height: 17px;
  margin: 5px 0px;
  text-align: center;
}

.para_wrapper {
  font-weight: 700;
  font-size: 24px;
  color: #323232;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.body {
  padding: 90px 0 0 60px;
}

.deal_header {
  padding-left: 20px;
  font-weight: 700;
  font-size: 24px;
  color: #323232;
}

.banner_wrapper {
  display: flex;
  justify-content: space-between;
}


.grid_box_130 {
  width: 130px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #001d56;
}

.box_text_deal {
  background: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #323232;
  padding: 10px;
  width: 110px;
  background: white;
  border-radius: 8px;
}

.investment_detail_wrapper {
  width: 192px;
  height: 82px;
  margin-left: 20px;
}

.investment_detail {
  font-weight: 700;
  font-size: 20px;
  color: rgb(50, 50, 50);
  margin-top: 0;
}

.shareHolding_asOn {
  font-weight: 700;
  font-size: 18px;
  color: rgb(0, 29, 86);
  margin: 30px 0px 0px;
}

.shareHolding_asOn_date {
  color: rgb(0, 29, 86);
  font-size: 18px;
  margin: 10px 0px;
}

.security_element {
  display: flex;
  height: 30px;
  align-items: center;
  font-size: 16px;
}

.security_element_text {
  font-weight: 600;
  width: 150px;
  color: rgb(0, 29, 86);
}


.bg_white {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}



.box_text_modal {
  background: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #323232;
  padding: 10px;
  min-height: 20px;
  padding: 10px;
  width: 130px;
  background: white;
}

.text_area_container {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  flex-direction: row;
}

.modal_paper {
  width: 580px;
  padding: 16px 32px 24px;
  box-shadow: -2px 2px 4px rgb(204 204 204 / 50%);
  border-radius: 32px;
  background-color: #fff;
  outline: none;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deal_header_wrapper {
  display: flex;
  align-items: center;
  color: #323232 !important;
  cursor: pointer;
}

.grid_box_fullWidth {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #001d56;
  margin: 15px 0px;
}

.as_on_date {
  color: rgb(0, 29, 86);
  font-size: 18px;
  margin: 8px 0;
}

.structure_textInput_container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.circular_progress {
  display: flex;
  align-items: center;
  justify-content: center;
}


.label {
  color: #323232 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  letter-spacing: -0.45px !important;
}

.projectName_Container {
  display: flex !important;
  position: relative !important;
  justify-content: space-between !important;
  align-items: center;
  width: 100% !important;
  align-content: center !important;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0 40px 0;
}

.outstanding_borowing_label {
  font-weight: 700 !important;
  color: #323232 !important;
  font-size: 18px !important;
}

.checkbox_and_text_container {
  background: #ffebee;
  padding: 8px 4px;
  border-radius: 12px;
  padding-left: 16px !important;
}

.debtProfile_checkbox {
  color: #3f51b5 !important;
  width: 24px !important;
  height: 24px !important;
}

.debtProfile_checkbox_label {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #323232 !important;
  padding-left: 8px !important;
}

.debtprofile_borowing_profile_container {
  margin: 12px 0 !important;
  background: rgba(0, 29, 86, 0.03) !important;
  border-radius: 12px !important;
  padding: 10px !important;
}

.borrowing_profile_date {
  display: flex !important;
  align-items: center;
  background: #fff;
  border-radius: 25px;
  height: 51px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 16px !important;
  outline: none;
  border: 1px solid lightGray;
  width: 100%;
}

.debt_profile_innerHeader {
  color: #001d56 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.debtprofile_outlined_input {
  background: #fff !important;
  border-radius: 8px !important;
  height: 34px !important;
  border: none !important;
  outline: none !important;
}

.debt_product_types_id_select {
  border-radius: 8px !important;
  background: #fff !important;
  height: 34px !important;
}

.debt_product_option {
  margin-top: 2rem !important;
  padding: 5px 10px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  border: 1px solid red !important;
}

.MuiSelect-icon {
  right: 2px !important;
  position: relative !important;
}

.MuiInput-root {
  border-radius: 25px !important;
}

.company_upload_lebel {
  cursor: pointer;
  margin-right: 8px;
}

.flex_center {
  display: flex;
  align-items: center;
}

.uploadBoxLebel {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.catalog_input_header {
  display: flex;
  justify-content: left;
  color: rgb(0, 29, 86);
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  margin-bottom: 10px !important;
}

.catalog_input_box {
  padding: 10px;
  max-width: 150px;
  background: white;
  margin-bottom: 10px !important;
  height: 40px;
}

.MuiOutlinedInput-input {
  padding: 11px !important;
}

#fin_subheader {
  margin-bottom: 35px;
  font-weight: 700;
}

.fin_textInput_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.Fin_Input_Label {
  color: #323232 !important;
  width: 390px;
  font-weight: 400;
  font-size: 18px;
}

.fin_add_deal_fileInput {
  height: 51px;
  width: 100%;
  max-width: 501px;
  outline: none;
  border-radius: 32px;
  font-size: 16px;
  border: 1px solid gray;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fin_add_deal_fileInput_p {
  margin-left: 7px;
  color: blue;
  width: 450px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  height: 24px;
}

.fin_DownloadIcon {
  width: 20px;
  height: 20px;
  margin: 4px 10px 0 35px;
  cursor: pointer;
  font-size: 30px;
}

.fin_textInput_container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.fin_add_Finupload_fileInput1 {
  height: 51px;
  width: 100%;
  max-width: 501px;
  outline: none;
  border-radius: 32px;
  font-size: 16px;
  border: 1px solid gray;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fin_box_buttons {
  margin: 30px 0;
}

.supportive_grid_container {
  margin: 12px 0;
  background: #efefef;
  border-radius: 16px;
  padding: 15px;
}

.supportive_input_label_heading {
  color: #001d56 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.MuiSelect-select:focus {
  background-color: white !important;
}

.supportive_select_Input {
  border-radius: 8px !important;
  background: #fff;
  color: #323232;
  font-size: 14px;
  height: 34px;
  height: 40px;
  outline: none;
  padding: 7px 8px !important;
}

.supportive_upload_p {
  margin-left: 7px;
  width: 400px;
  overflow: hidden;
  color: #323232;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.supportive_upload_icon {
  margin: 4px 6px 0 6px;
  cursor: "pointer";
}

.suppportive_add_more {
  border-radius: 24px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  color: #06283d;
}

#MuiButton_text {
  margin-top: 15px;
  border-radius: 16px;
}

#suppportive_add_more_endicon {
  background: #06283d !important;
  color: #fff;
  border-radius: 5px;
  margin-right: 5px;
}

.supportive_add_fileInput1 {
  height: 40px;
  width: 100%;
  outline: none;
  border-radius: 8px;
  font-size: 16px;
  background-color: white;
  display: flex;
  align-items: center;
}

.supportive_outlinedInput {
  background: #fff;
  border-radius: 8px;
  border: none;
  outline: none;
  color: Gray;
  height: 34px;
}

.supprotive_button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
}

#MuiGrid-container {
  width: 85% !important;
}

.pending_kyc {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: #001d56;
  background: #fee3d2;
  padding: 10px;
  border-radius: 36px;
}


.faq_next_button {
  background: linear-gradient(
    108.82deg,
    rgb(0, 62, 143) 25.52%,
    rgb(0, 29, 86) 85.05%
  ) !important;
  border-radius: 80px !important;
  min-width: 200px !important;
  width: fit-content !important;
  height: 50px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 16px !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.45px !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
}

.verified_email_button {
  position: absolute !important;
  right: 0;
  border-radius: 48px !important;
  text-transform: capitalize !important;
  height: 51px !important;
  width: 120px;
  font-weight: 400 !important;
  color: #ffffff !important;
  text-align: center;
  background-color: #cccccc !important;
  cursor: hand !important;
  box-shadow: none !important;
}

.helper-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #fb7d29 !important;
  margin-top: -3px !important;
  margin-left: 15px !important;
}

.success_text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: green !important;
  margin-top: -3px !important;
  margin-left: 15px !important;
}

.profile-otp-input {
  background: #e6e9ef !important;
  border: 3px solid #e6e9ef !important;
  border-radius: 42px !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  text-align: center;
  letter-spacing: -0.6px !important;
  color: #001d56 !important;
}

.profile-otp-empty-input {
  background-color: #e5e9f4 !important;
}

.grid_change_password {
  padding: 112px 120px;
  border-radius: 32px;
}

.background_change_pass {
  background-image: url("../assets/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  box-shadow: 2px 2px 6px 2px rgba(204, 204, 204, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 799px;
}

.background_logo {
  height: 100px;
  width: 100px;
}

.change_password_textInput .MuiOutlinedInput-notchedOutline {
  border-radius: 32px;
  border: 1px solid #cccccc;
  height: 51px;
}

.change_password_textInput .MuiOutlinedInput-root {
  /* height: 51px; */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  height: 51px;
  color: #323232;
  margin-bottom: 12px;
}

.change_password_textInput ::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #323232;
  mix-blend-mode: normal;
  opacity: 0.6;
}

.change_pass_submit_button {
  width: 100%;
  height: 51px;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: center;
  letter-spacing: -0.45px !important;
  color: #ffffff !important;
  background: linear-gradient(
    108.82deg,
    #003e8f 25.52%,
    #001d56 85.05%
  ) !important;
  border-radius: 50px !important;
  text-transform: none !important;
  box-shadow: none !important;
  margin-top: 70px !important;
}

/* .inputLabel {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #323232 !important;
  margin-top: 20px;
  margin-left: 15px;
} */

.back_to_label {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #323232 !important;
  margin-top: 45px !important;
  margin-right: 1% !important;
  margin-bottom: 30px !important;
}

.login_link_pass {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center;
  letter-spacing: -0.4px;
  text-decoration-line: underline !important;
  text-underline-offset: 3px !important;
  color: #fb7d29 !important;
  margin-left: 1.5px !important;
  margin-left: 4px !important;
}

.change_passowrd_heading {
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 58px !important;
  letter-spacing: -1.2px !important;
  color: #323232 !important;
  margin-bottom: 20px !important;
}

.change_password_subheader {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: -0.45px !important;
  color: grey !important;
  padding-bottom: 29px !important;
}

.inner_grid_chane_password2 {
  box-shadow: 2px 2px 6px 2px rgba(204, 204, 204, 0.5) !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  padding: 95px 50px 49.75px 44px;
  max-height: 799px;
}

.change_password_input_error {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: red !important;
  padding-top: 5px !important;
  padding-left: 5px !important;
}

.textDecoration {
  text-decoration: none;
  color: #001d56;
}



.accordian_value:hover {
  background: rgba(128, 128, 128, 0.424);
  cursor: pointer !important;
  transition: background 0.1s;
}
.back_button_grid {
  flex-direction: row !important;
  column-gap: 1% !important;
  margin-top: 25px !important;
}

.select_icon {
  float: "right";
  margin-right: 12px;
}
.select_icon_profile{
  float: "right";
  margin-right: 2%; 
  position: absolute !important;
  right: 0 !important;
  pointer-events: none !important;
}
.select_placeholder {
  font-style: normal !important;
  font-size: 16px !important;
}

.resent_otp_text_box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.demat_CMR_Copy_input .MuiOutlinedInput-input {
  visibility: hidden !important;
}

.upload_file_fileInput {
  border-radius: 65px !important;
  font-size: 16px !important;
  max-width: inherit;
  height: 51px !important;
  font-weight: 400 !important;
  color: #323232 !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  margin-bottom: 15px !important;
  border: 1px solid #cccccc !important;
}

.label_upload_cmr_copy {
  width: inherit !important;
  display: flex;
  justify-content: space-between;
  cursor: hand !important;
}

.placeholder_text_cmr_copy {
  font-size: 16px !important;
  font-family: Roboto;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #323232;
  padding: 12px 24px 16px 10px;
  opacity: 0.6;
}

.add_circle_icon {
  margin-right: 12px !important;
  margin-top: 12px !important;
  cursor: hand !important;
}

.upload_text_cmr_copy {
  font-size: 16px !important;
  font-family: Roboto;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #323232 !important;
  padding: 12px 24px 16px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.upload_text_cmr_copy_error{
  font-size: 16px !important;
  font-family: Roboto;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: red !important;
  padding: 12px 24px 16px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.kyc_form_control .MuiSelect-selectMenu {
  color: #323232 !important;
}

.success_pop_up .MuiDialog-paper {
  border-radius: 16px !important;
  padding: 35px 45px 25px 45px;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  height: 260px;
}

.succes_password_change_text {
  font-size: 22px !important;
  font-weight: 700 !important;
  padding-top: 10px !important;
}

.login_link_pass2 {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center;
  letter-spacing: -0.4px;
  text-decoration-line: underline !important;
  text-underline-offset: 3px !important;
  color: #fb7d29 !important;
  margin-left: 4.5px !important;
}

.sign_out_text {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  text-align: center !important;
  letter-spacing: -0.6px !important;
  color: #323232 !important;
  padding-bottom: 68px !important;
}

.sign_out_pop_up .MuiDialog-paper {
  border-radius: 16px !important;
  padding: 81px 65px 55px 65px;
  align-items: center;
  justify-content: center;
}

.sign_out_button {
  height: 51px !important;
  box-shadow: none !important;
  background: linear-gradient(
    108.82deg,
    rgb(0, 62, 143) 25.52%,
    rgb(0, 29, 86) 85.05%
  ) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: center !important;
  letter-spacing: -0.45px !important;
  color: #ffffff !important;
  width: 218px !important;
  border-radius: 36px !important;
  text-transform: none !important;
}

.otp_box_profile{
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:10%;
  margin-left: 12% !important;
}

.cancel_button {
  height: 51px !important;
  box-shadow: none !important;
  border: 1px solid #002c70 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: center !important;
  letter-spacing: -0.45px !important;
  color: #001d56 !important;
  width: 218px !important;
  border-radius: 36px !important;
  margin-right: 28px !important;
  text-transform: none !important;
}

.phone_no_field_value .MuiOutlinedInput-root .MuiOutlinedInput-input {
  margin-left: -5px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: none !important;
  display: none !important;
} */