.kyc_grid_parent{
    display: flex;
    flex: 1;
    max-width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%;
    border-radius: 20px;
}
#demat_detail_parent_box{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
}
.bankBox{
    margin-top: 4% !important;
}
.demat_detail_parent{
    border-radius: 10px;
    display: flex;
    flex-direction: column;  
    flex: 1;
    height: 100%;
}
.demat_detail_inner_1{
    display: flex;
    flex-direction: column;  
    flex: 7;
}
.demat_detail_inner_1_new{
    display: flex;
    flex-direction: column;  
    padding-bottom: 15%;
}
.demat_detail_inner_2{
    display: flex;
    flex-direction: column;  
    flex: 2;
    margin-top:10%;
    margin-bottom: 6%;
}
.demat_detail_inner_3{
    display: flex;
    flex-direction: column;  
    flex: 2;
    margin-bottom: 10%;
}
.kyc_background{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/background.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.inner_grid_kyc{
    box-shadow: 2px 2px 6px 2px rgb(204 204 204 / 50%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    max-height: 100%;
}
.inner_grid_kyc1{
    box-shadow: 2px 2px 6px 2px rgba(204,204,204,0.5);
    border-radius: 25px;
}
#kyc_verification_input_label{
    color: white;
    font-size: 32px;
    font-weight: medium;
}
.img_kyc{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 19%;
    left: 5.5%;
}
#kyc_debt_seekar{
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 19.2px;
    padding-bottom: 1%;
    padding-left:3%;
    color:black;
}
.seekar_kyc_box{
    border-radius: 10px;
    display: flex;
    flex-direction: column;  
    flex: 1;
    height: 100%;
}
.seekar_kyc_box_inner_2{
    display: flex;
    flex-direction: column;
    margin-top: 15%;
}
.kyc_form_control{
    width: 100%;
    border-radius: 25px !important;
    height: 51px !important;
}
.kyc_test{
    height:51px !important;
    width:100%;
    height: 51px;
    padding: 7px 8px;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    border: 1px solid gray;
    background-color: white;

}
#kyc_upload_box{
    width: 100%;
    display: flex;
    align-items: center;
    border-color: grey;
    border-style: dashed;
    border-radius: 90px;
    flex-direction: column;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 1%;
    margin-top: 4%;
    margin-bottom: 14%;
}
#kyc_upload_box1{
    width: 100%;
    display: flex;
    align-items: center;
    border-color: grey;
    border-style: dashed;
    border-radius: 90px;
    flex-direction: column;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 1%;
    margin-top: 4%;
    margin-bottom: 10%;
}
.demat_detail_upload_box1{
    width: 100%;
    display: flex;
    align-items: center;
    border-color: grey;
    border-style: dashed;
    border-radius: 90px;
    flex-direction: column;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 3%;
    margin-bottom: 2% !important;
}
.kyc_button_box{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.kyc_radio_button{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    margin-top: -5%;
}
.skip_label{
    font-size: 15px;
}
.debt_seeker{
    padding-top: 2%;
    padding-bottom: 1%;
    width: 100%;
    color:black;
    font-size:16px;
}
.app_bar{
    background-color: white; 
    color: black; 
    box-shadow: none;
}
.llp_box {
    padding-top: 4%;
    border-radius: 25px;
}
#label{
    padding-top: 1%;
    padding-bottom: 1%;
    color:grey;
    font-size:16px;
}
#label1{
    padding-top: 1%;
    padding-bottom: 2%;
    color:black;
    font-size:16px;
    margin-left: 15px;
}
.tab_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.cin_box{
    padding-top: 4%;
    border-radius: 25px;
}
.pan_box{
    padding-top: 4%;
    border-radius: 25px;
    margin-bottom: 2%;
}
.cloud_upload_icon_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.link{
    color: red;
    font-size: 15px;
    text-decoration: underline;
    margin-left: 1% !important;
    cursor: pointer;
}
.radio_checked_icon{
    color:darkblue;
}
#upload_label_kyc{
    color: blue;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
#upload_label_bank{
    color: blue;
    padding-top: 1%;
    padding-bottom: 4%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
#error_label{
    color: red;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 16px;
}
#upload_cancel_check_label{
    padding-top: 1%;
    padding-bottom: 1%;
    font-style: 'normal';
    color:black;
    font-size:16px;
}
#MuiAppBar-colorPrimary{
    background-color: white;
    color: black;
    box-shadow: none;
    width:100%;
}
#demate_tab{
    text-transform: none;
    font-size: 15px;
    color: darkblue;
    width: 100%;
}
.tab_box{
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#tab{
    text-transform: none;
    font-size: 15px;
    color: #001D56;
}
.demat_detail_pan_box{
    padding-top: 2%;
    border-radius: 25px;
    margin-bottom: 2%;
    width: 100%;
}
.kyc_arranger_input_label{
    margin-bottom: 1%;
    padding-top: 2%;
    width: 100%;
    color:black;
    font-size:16px;
    padding-left: 3%;
}
.kyc_arranger_parent_box{
 display: flex;
 flex-direction: column;
 justify-content: center;
}
.kyc_arranger_upload_box{
    width: 100%;
    display: flex;
    align-items: center;
    border-color: grey;
    border-style: dashed;
    border-radius: 90px;
    flex-direction: column;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 3%;
    margin-bottom: 10%;   
}
#kyc_upload_box2{
    width: 100%;
    display: flex;
    align-items: center;
    border-color: grey;
    border-style: dashed;
    border-radius: 90px;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 4%;
    margin-bottom: 10%;
}
.kyc_arranger_upload_box2{
    width: 100%;
    display: flex;
    align-items: center;
    border-color: grey;
    border-style: dashed;
    border-radius: 90px;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 3%;
    margin-bottom: 10%; 
    padding-left: 1%;
    padding-right: 1%;  
}
.kyc_next_button{
    background: linear-gradient(108.82deg, rgb(0, 62, 143) 25.52%, rgb(0, 29, 86) 85.05%) !important;
    border-radius: 80px !important;
    width: 200px !important;
    height: 50px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 16px !important;
    line-height: 1.5rem !important;
    letter-spacing: -0.45px !important;
    cursor: pointer !important;
}
.kyc_drop_box{
    height:34px !important;
    width:100%;
    height: 51px;
    padding: 7px 0px;
    outline: none;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-between;
    font-size: 16px;
    border: 1px solid gray;
    background-color: white;
    z-index: 1;
    cursor: pointer;
}
.drop_kyc{
    margin-top: 51% !important;
    border-radius: 15px !important;
    width:100.6% !important;
    margin-left: -0.2% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    position: absolute !important;
}
.kyc_drop_val{
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    cursor: pointer !important;
    width: 100% !important;
}
.kyc_val{
    color:#323232 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-left: 5% !important;
}
.drop_icon{
    cursor: pointer !important;
    margin-right: 2% !important;
}
.drop_provider{
    margin-top: 66% !important;
    border-radius: 15px !important;
    width:100.6% !important;
    margin-left: -0.2% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    position: absolute !important;
}
.drop_arranger{
    margin-top: 42% !important;
    border-radius: 15px !important;
    width:100.6% !important;
    margin-left: -0.2% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    position: absolute !important;
}
.bank_kyc{
    margin-top: 52% !important;
    border-radius: 15px !important;
    width:100.6% !important;
    margin-left: -0.2% !important;
    display: flex !important;
    height:200px !important;
    overflow-y: auto;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    position: absolute !important;
}
#demat_upload_label{
    color: blue !important;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 16px;
    width:100%;
}