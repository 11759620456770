.labelStyle {
    display: flex !important;
    align-items: center;
}



.label{
    color: #323232 !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    letter-spacing: -0.45px !important;
}

.ratingDetail{
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #323232 !important;
    letter-spacing: -0.45px;
}

.ratingDetailHeader{
    display: flex !important;
    justify-content: left;
    font-weight: 700 !important;
    font-Size: 16px;
    color: #001D56;
}

