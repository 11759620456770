.content_management_root {
  margin: 0px 10px 0px 8px !important;
  margin-top: 4% !important;
}

.content_management_root .MuiContainer-maxWidthLg {
  max-width: 1920px;
}

.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #fee3d2;
  height: 50px;
  border-radius: 30px;
  flex: 1;
  /* padding-left: 5%; */
}

.kyc_information_typography {
  color: #001d56;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.4px;
}

.button_outer_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  height: 71px;
}

.button_parent_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-width: 358px;
  height: 71px;
  border-radius: 12px;
  background: rgb(248, 248, 245);
  background-blend-mode: normal;
}

.button_color_active {
  background-color: #001e54 !important;
  text-transform: none !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 700;
  border-radius: 7px !important;
  letter-spacing: -0.45px;
}

.button_color {
  font-size: 18px !important;
  font-weight: 400;
  text-transform: none !important;
  letter-spacing: -0.45px;
}

.filter_parent_box {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  height: 20px;
  margin-top: 30px;
}

.filter_parent_box_debt_seeker {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  margin-top: 30px;
}

.filter_button {
  border-radius: 20px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  background: #ffffff !important;
  box-shadow: 2px 2px 8px rgba(58, 63, 99, 0.17);
  text-transform: none !important;
  color: #001d56 !important;
  letter-spacing: -0.4px !important;
}

.avatar_tag {
  background-color: rgb(255, 255, 255) !important;
  height: 10% !important;
}

.filter_icon {
  width: 38% !important;
  height: 30% !important;
}

.add_new_deal_button {
  text-transform: none !important;
  background-color: darkblue !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border-radius: 18px !important;
  font-weight: 700 !important;
  letter-spacing: -0.35px !important;
  margin-left: 24px !important;
}

.add_icon {
  background-color: darkblue;
  color: white;
  border-radius: 80px !important;
}

.cardWrapperDealDashboard {
  /* border: 1px solid #8080802e; */
  margin: 10px 10px 0 0px;
  border-radius: 8px;
  padding: 10;
  background-blend-mode: normal;
  width: 90%;
  max-width: 520px;
  box-shadow: 0px 0px 8px -1px rgba(0,0,0,0.19) !important;
  /* height: 530px; */
  background: #fff !important;
  border-radius: 12px !important;
}

.cardpadding p{
  margin: 0px;
}

.company__description{
  color: #323232 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  width:100% !important;
  text-align: justify;
  word-break:break-word;
}

@media (min-width: 768px) {
  .slick-slide {
    padding: 0 15px;
  }
}

.cardWrapperDealDashboard_manageDeal {
  border: 1px solid #8080802e;
  margin-top: 3% !important;
  border-radius: 8px;
  padding: 10;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  width: unset !important;
  /* min-height: 550px; */
  min-height: auto;
  margin-bottom: 2% !important;
}

.cardWrapperDealDashboard_approveDeal {
  border: 2px solid #fab007;
  border-radius: 8px;
  padding: 10;
  width: unset !important;
  min-height: 550px;
  margin-bottom: 15% !important;
}

.allocate_deal_popup .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 8px;
}

.allocate_deal_popup .MuiGrid-spacing-xs-3{
  align-items: self-start;
}

@media screen and (max-width:1650px) {
  .allocate_deal_popup .allocate_status_btn{
    padding: 14px;
  }
}

.history_cards .MuiGrid-grid-lg-2, .history_cards .MuiGrid-grid-lg-3{
  margin-right: 30px !important;
}

.history_cards .MuiGrid-grid-lg-2 {
  max-width: calc(16.66% - 30px) !important;
}

.history_cards .MuiGrid-grid-lg-3 {
  max-width: calc(25% - 30px) !important;
}

.history_cards .MuiGrid-grid-lg-7 {
  max-width: calc(58.33% - 30px) !important;
}

.history_cards .MuiGrid-spacing-xs-4 > .MuiGrid-item{
  padding: 5px;
}

.history_container div:nth-child(4), .history_container div:nth-child(5), .history_container div:nth-child(6){
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.history_container div:last-child, .history_container div:nth-last-child(2), .history_container div:nth-last-child(3) {
  /* border-radius: 8px !important; */
  border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.history_container{
  margin: 0px !important;
}

.history_cards .deal_history_remark{
  word-break: break-all !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cardWrapperDealDashboard_approveDeal_one {
  border: 2px solid #40c057;
  border-radius: 8px;
  padding: 10;
  width: unset !important;
  min-height: 550px;
  margin-bottom: 15% !important;
}

.company__name{
  height: 22px !important;
  color: #323232 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: -0.45px !important;
  margin-bottom: 12px !important;
}

.cardWrapperDealDashboard_approveDeal_rejected {
  border: 2px solid #686663;
  border-radius: 8px;
  padding: 10;
  width: unset !important;
  min-height: 550px;
  margin-bottom: 15% !important;
}

.take_action_button {
  text-transform: "none !important";
}

.deal_history_button {
  background: linear-gradient(
    108.82deg,
    rgb(0, 62, 143) 25.52%,
    rgb(0, 29, 86) 85.05%
  ) !important;
  border-radius: 80px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 18px !important;
  font-weight: 400;
  letter-spacing: -0.45px;
  width: 50% !important;
  cursor: pointer !important;
  font-weight: 400;
  text-transform: capitalize !important;
}

.icon {
  border-radius: 12px !important;
  margin-top: 20% !important;
}

.manage_deal_header {
  color: #323232 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  letter-spacing: -0.6px !important;
}

.inner_grid_manage_deal {
  margin-left: 3% !important;
  margin-right: 6% !important;
}

.name_typography {
  margin-right: 5% !important;
}

.name_typography_deal {
  margin: 0.5em 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.name_title {
  color: #323232 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: -0.4px !important;
}

.name_title_deal {
  color: #323232 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: -0.4px !important;
  margin: 0.5em 0 !important;
}

.header_status_typography {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: -0.5px !important;
  color: #323232 !important;
}

.approve_icon {
  width: 25% !important;
  height: 25% !important;
  margin-left: 63% !important;
  color: #40c057 !important;
  background-blend-mode: normal;
}

.pending_icon {
  width: 25% !important;
  height: 25% !important;
  margin-left: 62% !important;
  color: #fab007 !important;
  background-blend-mode: normal;
}

.rejected_icon {
  width: 25% !important;
  height: 25% !important;
  margin-left: 62% !important;
  color: #c2c2c2!important;
  background-blend-mode: normal;
}

.parent_box_approve_header {
  margin-top: 16% !important;
  margin-left: 12% !important;
  background-color: #f3f4f5 !important;
  height: 40% !important;
  border-radius: 12px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form_control_approve_deal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80% !important;
  margin-left: 3% !important;
  background-color: #fafafa !important;
  border-radius: 10px !important;
  height: 60% !important;
}

.manage_deal_button_parent {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  margin-top: 3% !important;
  margin-left: 4% !important;
  column-gap: 61% !important;
}

.card_parent {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 4% !important;
  column-gap: 2% !important;
}

.card_parent_one {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 4% !important;
  column-gap: 2% !important;
}

.edit_button_parent {
  display: flex !important;
  float: right;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background: #ffffff !important;
  box-shadow: 2px 2px 8px rgba(58, 63, 99, 0.169249) !important;
  border-radius: 12px !important;
  padding: 8px;
  /* margin-left: 75% !important; */
  /* margin-bottom: 80% !important; */
}

.responsive_image {
  width: 100% !important;
  height: auto !important;
  max-width: 24px !important;
}

.edit_button_child {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  column-gap: 20% !important;
}

.manage_deal_header_container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.approveCardDescriptionWrapper {
  background-color: #eeeeee !important;
  min-height: 70% !important;
  border-radius: 8px !important;
  max-height: 135px;
  overflow-y: auto;
}

.approveCardDescriptionWrapper::-webkit-scrollbar {
  display: none;
}

.approve_card {
  overflow-y: auto !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 8 !important;
  -webkit-box-orient: vertical !important;
  padding: 6px !important;
  height: 58% !important;
  font-size: 13px !important;
}

.deal_history_button_box {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-end !important;
  margin-top: 3% !important;
}

.onGoing_deals_card {
  min-height: 280px;
  background: #ffffff;
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.10383);
  border-radius: 32px;
  margin: 30px 8px;
  padding: 32px 34px;
}

.back-icon {
  margin-right: 29.1px !important;
  margin-top: 3px !important;
  cursor: pointer;
}

.ongoin-deal-card-heading {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  letter-spacing: -0.6px !important;
  color: #323232 !important;
  margin-bottom: 32px !important;
}

.deals_list_box {
  background: #f7f8fa;
  border-radius: 12px;
  padding: 45px;
}

.deals_list_header {
  /* height: 55px; */
  background: #eff1f5;
  border-radius: 12px;
  padding: 10px 0;
  margin-bottom: 16px;
}

.deals_values_box {
  margin-top: 16px !important;
}

.deals_list_header .heading-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #001d56;
}

.onging_deal_input {
  background: #ffffff !important;
  border-radius: 8px !important;
  border: hide !important;
  outline: none !important;
  height: 34px;
  padding: 4px !important;
  margin: 0;
  width: 160px;
}

.onging_deal_input_takeaction {
  border-radius: 8px !important;
  border: hide !important;
  outline: none !important;
  height: 34px;
  padding: 4px !important;
  background: none;
}

.onging_deal_input_takeaction .MuiOutlinedInput-input {
  background: #fff;
  border-radius: 8px;
}

.onging_deal_input_takeaction .MuiOutlinedInput-notchedOutline{
  border: none !important;
}

.onging_deal_input_takeaction .MuiInputBase-root.Mui-disabled{
  color: #000;
}

.deals_list_box .action-button {
  height: 34px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  color: #ffffff;
  background: linear-gradient(
    108.82deg,
    rgb(0, 62, 143) 25.52%,
    rgb(0, 29, 86) 85.05%
  ) !important;
  text-transform: none;
  border-radius: 8px;
  border: none;
  margin: 5px 5.15px 5px 5.15px;
  padding: 10px;
}

.onging_deal_input .MuiOutlinedInput-notchedOutline, .onging_deal_input_takeaction .MuiOutlinedInput-notchedOutline{
  border-color: #ffffff !important;
}

.onging_deal_input .MuiInputBase-input, .onging_deal_input_takeaction .MuiInputBase-input{
  height: 34px !important;
  text-align: center !important;
}

.onging_deal_input .MuiOutlinedInput-input{
  padding: 0px !important;
}
.onging_deal_input_takeaction .MuiOutlinedInput-input{
  padding: 5px 0px !important;
}

.onging_deal_input .Mui-focused,  .onging_deal_input_takeaction .Mui-focused{
  border-color: #ffffff !important;
  border-width: 1px !important;
}

.ongoing_deals_main_container .MuiContainer-maxWidthLg {
  max-width: 1920px;
}
.deal_history_detail_typography {
  color: #001d56 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: -0.4px !important;
  margin-bottom: 10px !important;
}
.deal_history_detail_data_box {
  border-radius: 8px !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-top: 5px !important;
  /* height: 85% !important; */
  overflow-y: hidden !important;
}
.deal_history_box {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  column-gap: 4% !important;
}
.deal_history_box_one {
  display: flex !important;
  flex-direction: column !important;
  row-gap: 4% !important;
  justify-content: space-between !important;
}
.deal_history_detail_typography_data {
  padding: 5px 10px !important;
}
.decision_box {
  flex: 1 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 3% !important;
  /* margin-bottom: 3% !important; */
}
.decision_box_one {
  flex: 1 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 3% !important;
}
.decision_input {
  height: 51px !important;
  width: 100% !important;
  height: 51px;
  padding: 7px 8px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid gray;
  background-color: white;
}
.decision_button_box {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  column-gap: 3% !important;
}
.check_icon {
  color: green !important;
  margin-left: 100% !important;
}
.cross_icon {
  color: red !important;
  margin-left: 250% !important;
}
.approve_button {
  text-transform: none !important;
  background-color: #ffffff !important;
  color: green !important;
  border-radius: 25px !important;
  padding-left: 3% !important;
  padding-right: 6% !important;
  width: 23% !important;
}
.reject_button {
  text-transform: none !important;
  background-color: #ffffff !important;
  color: red !important;
  border-radius: 25px !important;
  padding-right: 10% !important;
  width: 25% !important;
}
.cardWrapper_transaction_history {
  border: 1px solid #8080802e;
  margin: 10px 10px 0 0px;
  border-radius: 8px;
  padding: 10;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  width: unset !important;
  min-height: 86% !important;
  max-height: 100% !important;
}
.manage_deal_header_box {
  margin-top: 3% !important;
  margin-left: 3% !important;
}
.on_going_deal_button {
  max-height: 100% !important;
  margin-top: 3% !important;
  margin-bottom: 12% !important;
}

.ongoing_deal_popup_title .MuiTypography-h6 {
  display: flex;
}

.deal_name_heading {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: -0.45px !important;
  color: #001d56 !important;
  padding-bottom: 16px !important;
  margin-left: -22px !important;
}

.allocate_deal_popup .MuiPaper-root {
  background: #ffffff !important;
  box-shadow: -2px 2px 4px rgba(204, 204, 204, 0.5) !important;
  border-radius: 32px !important;
  min-width: 95% !important;
  min-height: 85% !important;
}

.allocate_deal_popup .MuiDialogContent-root {
  margin: 8px 32px !important;
}

.allocate_deal_popup .MuiDialogTitle-root {
  padding: 37px 34px !important;
  padding-bottom: 8px !important;
}

.deal_input_container .input_filed {
  display: flex;
  justify-content: flex-end;
}

.debt_provider_container {
  background-color: #f7f8fa;
  padding: 18px 24px !important;
  margin-bottom: 20px !important;
}

.debt_provider_header {
  background-color: #eff1f5;
  /* padding: 12px 27px; */
  margin: 0px !important;
}

.deal_box {
  background: #f7f8fa;
  border-radius: 12px;
  padding: 24px 32px 9px 32px;
  /* max-width: 322px !important; */
}

.deal_input_container {
  margin-bottom: 15px !important;
}

.deal_details_title_text {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #001d56 !important;
  align-self: center !important;
}

.deb_provider_header_text {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #001d56 !important;
}

.offer_chips {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  letter-spacing: -0.3px !important;
  color: #001d56 !important;
  height: 24px !important;
  padding: 4px 19.5px 6px 20px !important;
  background-color: white;
  border-radius: 16px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}

.provider_name_button {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #323232 !important;
  padding: 20px 25px 19px 26px;
  background-color: #fcfcfd;
  border-radius: 8px;
  overflow-x: auto;
}

.latest_offer_received {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #323232 !important;
  padding: 20px 20px 19px 20px;
  background-color: #fcfcfd;
  margin: 0px 8px 8px 0px;
}

.action_chips {
  /* height: 25px; */
  padding: 4px 0px 5px 0px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #001d56 !important;
  background: #ffffff;
  border: 1px solid #001d56;
  border-radius: 12.5px;
  margin-bottom: 6px !important;
  cursor: pointer;
}

.offer_accepted_row {
  background: rgba(95, 140, 228, 0.549088);
  border-radius: 12px;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.other_offer_row {
  padding: 0px 0px 0px 0px;
  margin-top: 12px !important;
}

.deals_divider {
  margin: 14.5px 0px 2.5px 0px !important;
  width: 100%;
  color: #001d56;
}

.offer_accepted_row_chips {
  background-color:
  #DCDCDC;
  /* height: 25px; */
  padding: 4px 0px 5px 0px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  text-align: center !important;
  color:#808080 !important; 
  
border: 1px solid #728FCE !important;
  border-radius: 12.5px;
  margin-bottom: 6px !important;
  cursor: default !important;
}
.action-chip-disable{
  /* background-color: #E8E8E8; */
  /* height: 25px; */
  padding: 4px 0px 5px 0px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #A9A9A9 !important;
  border: 1px solid#B0B0B0 !important;
  border-radius: 12.5px;
  margin-bottom: 6px !important;
  cursor: default !important;
  

}

.otp_popup .MuiPaper-root {
  background: #ffffff !important;
  box-shadow: -2px 2px 4px rgba(204, 204, 204, 0.5) !important;
  border-radius: 32px !important;
  min-width: 40% !important;
}

.otp_submit_button {
  /* background-color: #001e54 !important; */
  background: linear-gradient(109deg, #003e8f 0%, #001d56 100%);
  text-transform: none !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 700;
  border-radius: 7px !important;
  letter-spacing: -0.45px;
  box-shadow: none !important;
  border-radius: 32px !important;
  width: 136px !important;
  margin-top: 20px !important;
}

.submit_button_container {
  flex: 1 !important;
  display: flex !important;
  /* flex-direction: row !important; */
  justify-content: right !important;
  align-items: center !important;
  margin-top: 10% !important;
  height: 150px;
}

.confirm_cancle_button {
  background-color: white !important;
  border: 1px solid #001e54 !important;
  text-transform: none !important;
  color: #001e54 !important;
  font-size: 18px !important;
  font-weight: 700;
  border-radius: 7px !important;
  letter-spacing: -0.45px;
  box-shadow: none !important;
  border-radius: 32px !important;
  width: 136px !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.deal_history_title {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #323232 !important;
}

.deal_history_value {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #323232 !important;
  padding-left: 16px !important;
}

.grid_history_list_box {
  background: #f7f8fa;
  border-radius: 12px;
  padding: 19px 26px 25px 25px;
  max-height: 310px;
  overflow-y: auto !important;
}

.deal_history_header_text {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #001d56 !important;
  align-self: center !important;
}

.deb_provider_header_text {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: -0.4px !important;
  color: #001d56 !important;
}

.offer_chips {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  letter-spacing: -0.3px !important;
  color: #001d56 !important;
  height: 24px !important;
  padding: 4px 19.5px 6px 20px !important;
  background-color: white;
  border-radius: 16px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}

.provider_name_button {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #323232 !important;
  padding: 20px 25px 19px 26px;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.latest_offer_received {
  padding: 19px 8px 21px 8px !important;
  border-radius: 8px;
}

.upload_button_container {
  background: #fff !important;
  width: 180px !important;
  border-radius: 8px !important ;
  text-transform: capitalize !important;
}

.history_values_list_box {
  background: #ffffff;
  border-radius: 8px;
  padding: 0px 9.3px;
  /* max-height: 310px;
  overflow-y: auto; */
}

.deal_histoty_values_text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #323232 !important;
  padding: 8px 0px 17px 0px !important;
}

.bg_grey {
  background-color: gray !important;
}

.filterListHeading {
  font-family: Lato;
  font-weight: 400;
  font-size: 18;
  color: #001d56;
}

.filterListItem {
  font-family: Lato;
  font-weight: 400;
  font-size: 14;
  color: #323232;
}
.search_input{
  height: 48px;
  border:none;
  border-radius: 15px;
  padding-left: 10px;
  opacity: 0.6;
  position: absolute;
  width: 370px;
  color: #323232;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
}
input:focus{
  outline: none;
  background-color: #ffffff;
}

.dealsDashboardButtonsParent{ 
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-between;
  margin-top: 12px ;
}

.dealsDashboardButtonsParentForOnlyDealHistory{ 
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: flex-end;
  margin-top: 12px ;
}
.connectWithInvestorButton{
  
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  /* color: #323232 !important; */
  padding: 9px 9px 9px 9px;
  background-color: #fcfcfd;
  border: 1px solid #001d56;
  border-radius: 9px;
  color: #001d56 ;

}
.actionButton{
font-style: normal !important;
font-weight: 400 !important;
font-size: 12px !important;
line-height: 14px !important;
text-align: center !important;
letter-spacing: -0.3px !important;
color: #001d56 !important;
/* height: 24px !important; */
padding: 4px 9px 6px 9px !important;
background-color: white;
border-radius: 16px !important;
border: 1px solid #001d56;
/* margin-right: 8px !important; */
/* margin-bottom: 8px !important; */
cursor:pointer
}
.ongoingDealsChat{
background: linear-gradient(
  108.82deg,
  rgb(0, 62, 143) 25.52%,
  rgb(0, 29, 86) 85.05%
) ;
/* border-radius: 18px !important; */
color: rgb(255, 255, 255) ;
font-size: 18px !important;
font-weight: 400;
letter-spacing: -0.45px;
/* width: 54% ; */
cursor: pointer ;
text-transform: capitalize !important;
padding: 9px 27px;
}