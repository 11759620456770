.termAndCondition_paper{
    padding: 24px 32px !important;
    min-height: auto;
    margin: 16px 0 !important;
}

.termAndCondition_heading{
    font-weight: 700 !important;
    font-size: 24px !important;
}

.termAndCondition_content{
    padding-top: 40px !important;
}


.privacyPolicy_container{
    padding: 24px 32px !important;
    min-height: auto !important;
    margin: 16px 0
}

.privacyPolicy_heading{
    font-weight: 700 !important;
    font-size: 24px !important; 
}
.kyc_form_control .MuiSelect-selectMenu {
    color: #323232 !important;
  }
  .select_placeholder {
    font-style: normal !important;
    font-size: 16px !important;
  }
  .upload_file_fileInput {
    border-radius: 65px !important;
    font-size: 16px !important;
    max-width: inherit;
    height: 51px !important;
    font-weight: 400 !important;
    color: #323232 !important;
    line-height: 19px !important;
    letter-spacing: -0.4px !important;
    margin-bottom: 15px !important;
    border: 1px solid #cccccc !important;
  }
  .uploadBoxLebel {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
  }

  .company_upload_lebel {
    cursor: pointer;
    margin-right: 8px;
  }  