.paperContainer {
    display: flex;
    border-radius: 20px !important;
    background-color: rgba(255, 255, 255, 1);
    margin: 16px 0;
    box-shadow: 2px 2px 6px rgba(204,204,204,0.5);
  }

.signUp_leftContainer{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    /* width: 30%; */
    /* min-width: 300; */
    width: 100%;
    max-width: 500;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    /* border-bottom-right-radius: 10px; */
    box-sizing: border-box;
} 

.signUp_left_content_Container{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
    box-sizing: border-box;
    min-height: 100%;
    padding: 50px 40px 88px 40px;
    /* border: 1px solid red; */
  }

  .finLog {
    /* height: 100px;
    width: 89px; */

    width: 89px;
    height: 100px;
    background: no-repeat;
  }

  .left_signup_text{
    width: 148px !important;
    height: 60px !important;
    color: #ffffff;
    /* font-family: Roboto !important; */
    /* font-family: "Roboto-Regular" !important; */
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif "!important;
    font-size: 50px !important;
    font-weight: 500 !important;
    letter-spacing: -1.25px !important;
  }

  .alreday_have_aacount_text{
    /* color: "#fff",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px" */
    display: flex !important;
    /* width: 173px !important; */
    height: 19px;
    color: #ffffff !important;
    /* font-family: Roboto !important; */
    font-size: 16px !important;
    font-weight: 400 !important;
    letter-spacing: -0.4px !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif "!important;
  }

  .sign_link{
    width: 37px;
    height: 19px;
    color: #fb7d29 !important;
    /* font-family: Roboto !important; */
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif "!important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: center !important;
    text-decoration: underline !important;
    letter-spacing: -0.4px !important;
    margin-left: 8px !important;
    cursor: pointer;
  }


 .signUp_right_content_Container {
    max-width: 501px;
    width: auto;
    font-size: 16px !important;
    font-weight: 400 !important;
    /* border: 1px solid yellow */
  }

.signUp_right_roleContainer{
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  margin-bottom: 8px;
}

.signUp_rightContainer {
  display: flex !important;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  width: 60% !important;
  padding: 16px 8px;
}
.signUp_right_textInput_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.singup_inputLabel{
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #323232 !important;
  margin-bottom: 5px !important;
  padding-left: 16px !important;
}

.error_message{
  font-weight: 400 !important;
  color: red !important;
  padding-left: 16px !important;
  margin-top: 3px !important;
}

.show_password_helper_text{
  font-weight: 400 !important;
  padding-left: 16px !important;
  margin-top: 3px !important;
  color: gray !important;
}

.email_verified_text,.mobile_verified_text{
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  color: #99e064 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.verified_tick_logo{
  margin-left: 4px !important;
}

.verify_email_button{
  position: absolute !important;
  right: 0;
  border-radius: 48px !important;
  text-transform: capitalize !important;
  height: 51px !important;
  width: 120px;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 1) !important;
  text-align: center;
    background: linear-gradient(108.82deg, rgb(0, 62, 143) 25.52%, rgb(0, 29, 86) 85.05%);
  cursor: hand !important;
}
.verify_email_button_disabled{
  position: absolute !important;
  right: 0;
  border-radius: 48px !important;
  text-transform: capitalize !important;
  height: 51px !important;
  width: 120px;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 1) !important;
  text-align: center;
    background: #E8E8E8;
}

.send_email_verification_message{
  font-weight: 400 !important;
  font-size: 16px !important;
}

.phone_number_container{
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label_91_mobile{
  position: absolute !important;
  font-weight: 400 !important;
  left: 12px !important;
  display: flex;
  align-items: center;
  border-radius: 48px;
  margin-right: 16px;
  width: 20px;
}

.signUp_right_textInput_mobile{
  height: 51px;
  width: 100%;
  padding: 10px 16px 10px 45px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid rgb(170, 168, 168)
}

.email_input_container{
  height: 51px;
  width: 100%;
  padding: 10px 16px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  color: #323232;
  border: 1px solid rgb(170, 168, 168)

}

.request_otp_button{

  position: absolute !important;
  right: 0;
  border-radius: 48px !important;
  text-transform: capitalize !important;
  height: 51px !important;
  width: 120px;
  color: rgba(255, 255, 255, 1) !important;
  text-align: center;
    background: linear-gradient(108.82deg, rgb(0, 62, 143) 25.52%, rgb(0, 29, 86) 85.05%);
}
.request_otp_button_disabled{

  position: absolute !important;
  right: 0;
  border-radius: 48px !important;
  text-transform: capitalize !important;
  height: 51px !important;
  width: 120px;
  color: rgba(255, 255, 255, 1) !important;
  text-align: center;
  background: #E8E8E8;
}


.mobile_otp_send_text_message{
  font-weight: 400 !important;
  font-size: 16px !important;
color: #323232 !important;
opacity: 0.65;
}

.did_not_receive_code{
  font-weight: 400 !important;
  font-size: 16px !important;
 color: #323232 !important;


}

.resend_code{
cursor: pointer;
margin-left: 4px !important;
font-weight: 400 !important;
font-size: 16px;
line-height: 19px;
color: #001D56 !important;
text-decoration-line: underline;

font-family: 'Roboto';
font-size: 16px;
line-height: 19px;

}

.signup_button{
  border-radius: 24px !important;
  opacity: 1;
  height: 51px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-size: 18px !important;
}

.emailInputContainer{
  display: flex ;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-content: center;
}

.signUp_right_textInput {
  height: 51px !important;
  width: 100%;
  padding: 10px 16px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  color: #323232 !important;
  border: 1px solid gray
}

.privacyPolicy {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19.2px !important;
  padding-left: 10px !important;
  color: #323232 !important;
}

.otp_container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}