.grid_parent{
    display: flex;
    flex: 1;
    height: 100%;
    max-width: 100%;
    padding: 4%;
    border-radius: 20px;
}
.img{
    width: 100;
    height: 100
}
.baground{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/background.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    border-top-left-radius: 5%;
    border-bottom-left-radius: 5%;
}
.parent_box{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#MuiTypography-root{
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.2px;
}
.app_bar_mobile{
    background-color: white !important;
    color: black !important;
    box-shadow: none !important;
    width:84% !important;
}
.tab_box{
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#tab{
    text-transform: none;
    font-size: 15px;
    color: darkblue;
}
.parent_box_textfield{
    width: 85%;
}
#MuiOutlinedInput-root + fieldset{
    border-radius: 8px;
}
.box{
    padding-top: 10%;
}
.password_box{
    padding-top: 5%;
}
.forgot_password_mobile_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.forgot_password_mobile_link{
   color:red !important;
   font-size:15px !important;
   text-decoration:underline !important;
   margin-top:11% !important;
}
.forgot_password_mobile_link_with_otp_box{
    color:red !important;
   font-size:15px !important;
   text-decoration:underline !important;
   margin-top:23% !important;
}
.dont_have_account_label{
    font-size: 15px;
    text-align: center;
    margin-top: 4%;
}
#button{
    padding-left: 47%;
    padding-right: 47%;
    text-transform: none;
    color: white;
    margin-top: 5%;
}
#email_label{
    color: #323232;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
}
#password_label{
    color: #323232;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
}
#link{
    text-decoration:underline;
    color:darkblue;
    font-size:13px;
}
#error{
    color:red !important;
}
.box-login-with-mobile{
    padding-top:1%;
}
.output-input-label{
    font-size: 15px;
}
.otp_box{
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10%;
}
.otp_resend_label{
   /* margin-top:5%; */
   font-size:15px;
}
.inner_grid{
    box-shadow: 2px 2px 6px 2px rgba(204,204,204,0.5);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 25px;
}
.inner_grid1{
    box-shadow: 2px 2px 6px 2px rgba(204,204,204,0.5);
    border-radius: 25px;
}