.grid_parent_email{
    display: flex;
    flex: 1;
    min-height: 100%;
    max-width: 100%;
    padding: 4%;
    border-radius: 20px;
}
.inner_grid_email{
    box-shadow: 2px 2px 6px 2px rgba(204,204,204,0.5) !important;
    border-top-right-radius: 5% !important;
    border-bottom-right-radius: 5% !important;
    padding: 10px 35px;
    height: 100%;
}
.inner_grid1_email{
    box-shadow: 2px 2px 6px 2px rgba(204,204,204,0.5) !important;
    border-radius: 4% !important;
}
.img{
    width: 100;
    height: 100
}
.background_email{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/background.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.parent_box{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
#MuiTypography-root{
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -1.2px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.app_bar_email{
    background-color: white !important;
    color: black !important;
    box-shadow: none !important;
    width:100% !important;
    top:36px !important;
}
.tab_box{
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#tab{
    text-transform: none;
    font-size: 15px;
    color: #001D56;
}
.parent_box_textfield{
    width: 100%;
}
#MuiOutlinedInput-root + fieldset{
    border-radius: 8px;
}
.box{
    padding-top: 10%;
}
.password_box{
    padding-top: 5%;
}
.password_box .MuiFormLabel-root, #email_parent .MuiFormLabel-root, .password_box .MuiFormLabel-root.Mui-focused, .box .MuiFormLabel-root.Mui-focused{
    color: black;
}
#email_parent .MuiOutlinedInput-root, .password_box .MuiOutlinedInput-root, .box .MuiOutlinedInput-root{ 
    border-radius: 8px; 
}

.Mui-focused:after{
    border: 1px solid #82BE78 !important;
}

.forgot_password_email_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
}
#forgot_password_email_link{
    color: #FB7D29;
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
}
#sign_up_link{
    color: #FB7D29;
    font-size: 15px;
    text-decoration: underline;
    margin-top: 1%;
    margin-left: 1% !important;
    cursor: pointer;
}
.dont_have_account_label{
    font-size: 15px;
    text-align: center;
    margin-top: 4%;
    }
.dont_have_account_box{
    margin-top: 5%;
    padding-bottom: 5% !important;
}
#button{
    padding-left: 47%;
    padding-right: 47%;
    text-transform: none;
    color: white;
    margin-top: 5%;
}
#email_label{
    color: #323232;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
}
#password_label{
    color: #323232;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
}
.email_login_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}