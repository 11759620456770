#management_profile_label {
  color: #323232;
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
}
.add_more_button_box {
  margin-bottom: 5%;
}

.management_profile_remarks{
  height:51px !important;
  width:100%;
  padding: 10px 10px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid gray;
  background-color: white;
  white-space: nowrap;
  resize: none;
  overflow-y: hidden;
  overflow-x: auto;
}
.add_more_button {
  display: flex;
  align-items: center;
  background: white;
  border: none;
  box-shadow: 1px 2px 4px 1px #6f6fa45c;
  border-radius: 30px;
  padding: 7px 16px;
  font-weight: bold;
  color: rgb(4, 4, 84);
  margin: 35px 0 0px 0;
  cursor: pointer;
}
.management_button_box {
  display: flex !important;
  width: 85%;
  margin-top: 2% !important;
  margin-bottom: 5%;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  column-gap: 1% !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #001d56;
  text-transform: none !important;
}
.management_profile_form_grid_parent {
  border: 1px solid grey;
  margin-top: 10px;
  border-radius: 15px;
  margin-right: 10%;
  margin: 0 !important;
}
.management_profile_form_grid_parent_one {
  border: 1px solid grey;
  margin-top: -3% !important;
  border-radius: 15px;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}
.management_clost_button {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -8% !important;
}
.management_label {
  font-size: 18px !important;
  color: #323232;
  font-weight: 400 !important;
}
.management_label_name {
  font-size: 18px !important;
  color: #323232;
  font-weight: 400 !important;
  margin-top: 10% !important;
}
.remove_upload_file {
  cursor: pointer;
  font-size: "13px";
  color: #323232;
  padding-right: 10px;
}
.management_box {
  margin-top: 5%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 15%;
}
.form_control {
  min-width: 77px !important;
  max-width: 77px !important;
  border-radius: 25px !important;
}
.select_tag {
  border-radius: 25px !important;
  height: 51px !important;
  margin-left: -2px !important;
}
.management_right_container {
  display: flex;
  flex-direction: row;
  margin-top: 3% !important;
  justify-content: space-between;
}
.management_parent_container {
  padding: 10px;
  border: 1px solid grey;
  border-radius: 25px;
}
.management_error_label {
  color: red;
  font-size: 18px;
}
.managemnet_upload_label {
  display: flex;
  /* flex: 0.1; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1%;
}
.management_upload_box {
  border: 1px solid grey;
  border-radius: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 3%;
  height: 51px !important;
}
.managemnet_upload_box_label {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer !important;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
}
.director_upload_box {
  border: 1px solid grey;
  border-radius: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 3%;
  height: 51px !important;
}
.director_upload_box_label {
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  /* align-items: center; */
  cursor: pointer !important;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1% !important;
}
.director_grid_parent {
  border: 1px solid grey;
  margin-top: 10px;
  border-radius: 15px;
  margin-right: 10%;
  margin-left: 0px !important;
}
.director_grid_parent_one {
  border: 1px solid grey;
  margin-top: -3% !important;
  border-radius: 15px;
  margin-right: 10% !important;
  margin-left: 0px !important;
}
.file_director_name {
  display: flex;
  flex: 0.9;
  padding-left: 7px;
}
.director_placeholder {
  display: flex;
  font-size: 14px;
  /* flex: 0.9; */
  padding-left: 7px;
  color: grey;
  padding-bottom: 5%;
}
.texfield {
  margin-left: 30px !important;
}
.management_text_input {
  height: 51px;
  width: 100%;
  padding: 7px 8px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid #8b8686b5;
  background-color: white;
}
.director_text_input {
  height: 51px;
  width: 100%;
  padding: 7px 8px;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid #8b8686b5;
  background-color: white;
}
.drop_down{
    height:51px !important;
    width: 19%;
    padding: 7px 8px;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    border: 1px solid gray;
    background-color: white;
    display:flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}
.default_drop_down_label{
    color:grey !important;
}
.drop_down_icon{
  margin-left: 10% !important;
  cursor:  pointer !important;
  color:#323232 !important;
}
.drop_down_value{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important;
}
.drop{
    margin-top: 17% !important;
    border-radius: 15px !important;
    width:6.4% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    position: absolute !important;
}
.drop_val{
    margin-top: 8% !important;
    margin-bottom: 12% !important;
    cursor: pointer !important;
    width: 100% !important;
}
.val{
    color:#323232 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-left: 25% !important;
}
.divider1{
    color: lightgrey !important;
}
